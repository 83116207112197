.included {
  background: $main-color;
  padding: 45px 45px 30px;
  h2 {
    color: #FFF;
    font-size: rem(32); }
  ul {
    list-style: none;
    li {
      color: #FFF;
      font-size: rem(17);
      margin-bottom: 5px;
      position: relative;
      &:before {
        content: '';
        width: 24px;
        height: 24px;
        display: block;
        border-radius: 12px;
        background: $blue url("../img/check.svg") no-repeat;
        background-position: center;
        position: absolute;
        left: -40px; } } } }

@media screen and (max-width: 991px) {
  .included {
    padding: 30px 30px 20px;
    h2 {
      margin-bottom: 20px;
      br {
        display: none; } } } }

@media screen and (max-width: 400px) {
  .included {
    h2 {
      font-size: rem(24); } } }
