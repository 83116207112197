.order-page {
  .catalog-item {
    margin-bottom   : 0;
    &:hover {
      box-shadow: none; } }
  .order-form {
    display: block;
    background: $main-color;
    position: relative;
    padding: 30px 40px;
    margin-top: 2px;
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 20px 15px 20px;
      border-color: transparent transparent $main-color transparent;
      position: absolute;
      top: -14px;
      left: calc(50% - 20px); }
    .order-form-wrapper {
      max-width: 260px;
      margin: 0 auto; }
    h4 {
      font-size: rem(24);
      color: #FFF;
      margin-bottom: 25px; }
    .form-group {
      label {
        color: #FFF;
        font-size: rem(15);
        font-weight: 500; }
      .form-control {
        padding: 0 15px; }
      .inline-inputs {
        display: flex;
        position: relative;
        .date-input {
          width: 66%;
          background: #FFF url("../img/calendar.svg");
          background-repeat: no-repeat;
          background-position: 90% center; }
        .time-input {
          border-left: solid 1xp $main-color;
          width: calc(34% - 1px);
          background: #FFF url("../img/timepicker-arrow.svg");
          background-repeat: no-repeat;
          background-position: 90% center; } } }
    .filter-label {
      color: #FFF; } }
  .form-results {
    margin-top: 2px;
    padding: 30px 41px;
    background: #FFF;
    h4 {
      color: var(--black, #191919);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      margin-bottom: 26px; }

    span {
      font-size: rem(32);
      font-weight: 500; }
    p {
      display: flex;
      align-items: center;
      color: #191919;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      margin-bottom: 26px;
      &:last-child {
        margin-bottom: 42px; }
      b {
        width: 60%;
        display: block;
        font-weight: 500; }
      span {
        font-weight: 500;
        &.pay-online {
          color: #D9030E; } } }
    .filter-label {
      margin-top: 15px;
      margin-bottom: 28px;
      font-size: 15px; }
    .btn {
      font-weight: 500;
      width: 100%;
      background: $red;
      color: #FFF;
      transition: all linear .15s;
      margin-right: 15px;
      max-width: 230px;
      &:hover {
        background: $orange;
        transition: all linear .15s; }
      &:active {
        background: $dark-red; }
      &.processing {
        opacity: .7 !important;
        cursor: not-allowed; } }

    .loader {
      width: 32px;
      height: 32px;
      border: 3px solid #FFF;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;

      &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: 3px solid;
        border-color: #FF3D00 transparent;


        @keyframes rotation {
          0% {
            transform: rotate(0deg); }
          100% {
            transform: rotate(360deg); } } } } } }

.order-info {
  margin-bottom: 100px;

  .joung-driver {
    p:first-child {
      margin-bottom: 7px; }

    .joung-driver-description {
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      max-width: 225px;
      display: inline-block;
      position: relative;
      padding-left: 31px;
      margin-bottom: 30px !important;

      &:before {
        content: '';
        display: block;
        width: 14px;
        height: 15px;
        border-left: 1px solid #25B1CF;
        border-bottom: 1px solid #25B1CF;
        position: absolute;
        left: 8px; } } } }

.full-price, {
    .discount {
      width: 100%; }

    .discount {
      color: #D9030E;

      p {
        color: #191919; } }

    .bolder {
      font-weight: 500; }

    .outline-white {
      background-color: white;
      border: 1px solid #191919;
      border-radius: 50px;
      color: #191919;
      transition: all .15s ease-out;

      &:disabled {
        border-color: transparent;

        &:hover {
          border-color: transparent; } }

      &:hover {
        border: 1px solid #F16500;
        background: #F16500;
        color: white; } } }

.tooltipster-sidetip {
  &.tooltipster-top {
    .tooltipster-arrow-border {
      border-top-color: #D9030E; }
    .tooltipster-arrow-background {
      border-top-color: white; } }

  &.tooltipster-bottom {
    .tooltipster-arrow-border {
      border-bottom-color: #D9030E; }
    .tooltipster-arrow-background {
      border-bottom-color: white; } }

  .tooltipster-box {
    background: white;
    border: 2px solid #D9030E;
    .tooltipster-content {
      color: #D9030E; } } }


@media screen and (max-width: 991px) {
  .order-page {
    .order-form {
      h4 {
        font-size: rem(20); } }
    .form-results {
      .col-md-12 {
        text-align: center;
        margin-top: 30px; }

      .reservation-description p {
        width: 100%; }

      .full-price, .discount {
        width: 100%; }

      #form-submit, #form-submit-payment {
        //width: calc(100% - 30px)
        display: inline-block;
        margin: 0 15px;
        font-size: 15px; } } } }

@media screen and (max-width: 767px) {
  .order-page {
    .form-results {
      .btn {
        margin-top: 30px; } }

    .container-ordering {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      .catalog-item.last-on-mobile {
        order: 4; }
      .form-results {
        order: 3; } } } }

@media screen and (max-width: 575px) {
  .order-page {
    .order-form {
      h4 {
        font-size: rem(17); }
      .filter-label {
        margin-right: 0;
        font-size: rem(15);
        line-height: 1.8; } }
    .form-results {
      padding: 15px 20px 30px;
      margin-bottom: 0;
      border-bottom: solid 1px #cccccc;
      span {
        font-size: rem(24); }
      p {
        font-size: rem(15); }

      .reservation-description p {
        padding: 0 5px; }

      #form-submit, #form-submit-payment {
        width: calc(50% - 14px);
        margin: 0 15px;
        padding: 0 10px; }
      h4 {
        text-align: center; } }

    .catalog-item {
      .included-options {
        margin-top: 23px; } } } }

@media screen and (max-width: 430px) {
  .order-page {
    .container {
      padding: 0;
      .car-params {
        margin-bottom: 10px; } }
    .order-form {
      padding: 20px; } } }
