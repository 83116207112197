.too-long-interval {
  padding-top: 63px;
  padding-bottom: 63px;

  .wrapper {
    width: 620px;
    margin: 0 auto;
    max-width: 100%; }

  h1 {
    margin: 0 0 25px;
    padding: 0;
    color: #191919;
    text-align: center;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; }

  p,
  b,
  a {
    font-size: 16px;
    font-style: normal;
    line-height: 120%; }

  p {
    color: #191919;
    font-weight: 400;
    margin-bottom: 39px; }

  b {
    margin-bottom: 25px;
    color: #191919;
    font-weight: 600;

    &.mb-0 {
      margin-bottom: 0; } }

  a {
    color: #D9030E;
    font-weight: 400;
    text-decoration-line: underline;

    &:hover {
      text-decoration: underline dotted; } } }

@media (min-width: 1000px) {
  .too-long-interval {
    h1 {
      text-align: left; }

    p,
    b,
    a {
      font-size: 21px; } } }
