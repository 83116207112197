.van-types {
  background: #FFF url("../img/types-bg.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center;
  padding: 60px 0;
  text-align: center;
  .owl-carousel {
    margin-top: 60px;
    .owl-nav {
      position: absolute;
      top: 50%;
      margin-top: -80px;
      display: flex;
      width: 50%;
      left: 50%;
      margin-left: -25%;
      justify-content: space-between;
      flex-direction: row-reverse;
      button {
        display: block;
        width: 60px;
        height: 60px;
        background: #FFF;
        border-radius: 30px;
        font-size: 36px;
        transition: all linear .15s;
        span {
          margin-top: 7px;
          display: block;
          transform: rotate(180deg); }
        &:hover {
          background: $light-blue;
          transition: all linear .15s; }
        &:active {
          background: $main-color; } } }
    .item {
      height: 470px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      overflow: visible;
      h4 {
        font-size: rem(28);
        font-weight: 500; }
      p {
        font-size: rem(17); }
      img {
        height: 100%;
        object-fit: contain; } } }
  .btn-red {
    padding: 0 50px;
    text-align: center;
    margin: 0 auto; }
  .dots-container {
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .owl-dot {
      border: none;
      width: 20px;
      height: 2px;
      background: #CCC;
      cursor: pointer;
      margin: 0 10px;
      &.active {
        background: $red; } } } }

@media screen and (max-width: 1199px) {
  .van-types {
    background-size: 2000px;
    .owl-carousel {
      .item {
        height: auto;
        img {
          max-height: 400px; } }
      .owl-nav {
        width: 90%;
        margin-left: -45%; } } } }

@media screen and (max-width: 991px) {
  .van-types {
    background-size: 1700px;
    .owl-carousel {
      .item {
        height: auto;
        img {
          max-height: 300px; } }
      .owl-nav {
        width: 90%;
        margin-left: -45%; } } } }

@media screen and (max-width: 768px) {
  .van-types {
    padding-top: 40px;
    background-size: 1600px;
    .section-title {
      font-size: rem(42); }
    .owl-carousel {
      .owl-nav {
        top: calc(100% + 78px);
        button {
          background: transparent;
          border: solid 2px $red;
          span {
            color: $text-color; }
          &:hover {
            border: solid 2px $orange;
            background: $orange;
            span {
              color: #FFF; } }
          &:active {
            border: solid 2px $dark-red;
            background: $dark-red;
            span {
              color: #FFF; } } } } } } }


@media screen and (max-width: 480px) {
  .van-types {
    padding-top: 20px;
    background-size: 1000px;
    .section-title {
      font-size: rem(32); }
    .owl-carousel {
      margin-top: 30px;
      .item {
        h4 {
          font-size: rem(24); } } } } }

@media screen and (max-width: 430px) {
  .van-types {
    background-size: 750px;
    .section-title {
      font-size: rem(28); }
    .owl-carousel {
      margin-top: 30px;
      .item {
        padding: 0 10px; }
      .owl-nav {
        .owl-prev {
          border: none; }
        .owl-next {
          border: none; } } }
    .btn-red {
      padding: 0 30px; } } }
