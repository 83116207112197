footer {
  background: #FFF;
  padding-bottom: 50px;
  padding-top: 60px;
  border-top: solid 1px #cccccc;
  .footer-icons {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 40px;
    a {
      display: flex;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      border: solid 1px #D9030E;
      margin-right: 15px;
      align-items: center;
      justify-content: center;
      transition: all linear .15s;
      &:hover {
        background: $orange;
        border-color: $orange;
        transition: all linear .15s; }
      &:active {
        background: $dark-red;
        border-color: $dark-red;
        transition: all linear .15s; } } }
  .footer-phone {
    font-size: rem(32);
    color: $text-color; }
  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 40px;
    li {
      a {
        font-size: rem(15);
        color: $main-color;
        transition: all linear .15s;
        &:hover {
          color: $red;
          transition: all linear .15s; } } } } }

.copyright {
  background: #FFF;
  padding-bottom: 90px;
  color: $gray;
  font-size: rem(15);
  a {
    color: $gray;
    font-size: rem(15); } }

.remodal-overlay {
  background: rgba($dark-blue, .9) url("../img/404-bg.svg")  !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 7% !important; }
.remodal {
  max-width: 380px;
  padding: 0;
  .remodal-close {
    right: 0;
    left: auto;
    width: 40px;
    height: 40px;
    font-size: 12px;
    &:before {
      font-size: 35px;
      color: $blue;
      transition: all linear .15s; }
    &:hover {
      &:before {
        color: $light-blue;
        transition: all linear .15s; } } }
  .modal-header {
    border-radius: 0;
    padding: 20px 30px;
    background: $dark-blue;
    h3 {
      text-align: left;
      color: #FFF;
      font-size: rem(20);
      font-weight: 500; } }
  form {
    padding: 30px 30px 40px;
    .form-group {
      margin-bottom: 20px;
      text-align: left;
      label {
        font-size: rem(15);
        font-weight: 500; }
      .form-control {
        height: 46px;
        border: solid 1px #cccccc; } }
    .filter-label {
      text-align: left;
      font-size: rem(15);
      margin-right: 0;
      line-height: 18px;
      .checkmark {
        top: 3px; } }
    button {
      width: 100%;
      height: 54px;
      background: $red;
      color: #FFF;
      border-radius: 27px;
      font-size: rem(18);
      margin-top: 20px;
      font-weight: 500;
      transition: all linear .15s;
      &:hover {
        background: $orange;
        transition: all linear .15s;
        color: #FFF; }
      &:active {
        background: $dark-red;
        color: #FFF; } } } }

.cookies-warning {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 20;
  padding: 31px 38px 44px;
  border-radius: 7px;
  background: #FFF;
  box-shadow: 0 25px 50px 0 rgba(130, 128, 141, 0.25);
  max-width: calc(100% - 40px);
  box-sizing: border-box;

  .wrapper {
    position: relative; }

  .close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 5px;
    right: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:before {
      width: 12px;
      height: 12px;
      content: '';
      display: block;
      background-image: url('/assets/img/close.svg');
      background-size: contain; } }

  .title {
    margin-bottom: 33px;
    color: #191919;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center; }

  .description {
    margin-bottom: 18px;
    color: #515155;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; }

  a {
    color: #D9030E;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline; }

  .red-button {
    margin-bottom: 0;
    font-weight: 600;
    display: inline-flex;
    border: none;
    border-radius: 7px;
    background: linear-gradient(140deg, #FF545D 0%, #BF0912 100%);
    padding: 7px 37px;
    text-align: center;
    color: white;

    &:hover {
      background: linear-gradient(100.45deg, #E62F38 -5.27%, #920810 107.93%);
      box-shadow: 0 8px 24px rgba(168, 19, 26, 0.25); } } }

@media screen and (max-width: 767px) {
  footer {
    .footer-flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .footer-icons {
        max-width: 230px;
        margin-top: 0; } }
    .footer-phone {
      margin-top: 30px;
      display: block; } } }

@media screen and (max-width: 430px) {
  footer {
    text-align: center;
    img {
      margin: 20px auto; }
    .footer-flex {
      width: 100%;
      display: block;
      .footer-icons {
        width: 100%;
        display: flex;
        justify-content: center;
        max-width: none;
        margin-top: 0; } }
    .footer-phone {
      margin-top: 30px;
      display: block;
      font-size: rem(24); }
    ul {
      margin: 20px 0 0; } } }

@media screen and (min-width: 500px) {
  .cookies-warning {
    width: 371px; } }
