.qs-datepicker-container {
  border-radius: 0;
  border-color: $main-color;
  .qs-datepicker {
    .qs-controls {
      background: $blue;
      color: #FFF;
      .qs-arrow {
        &.qs-left {
          &:after {
            border-right-color: #FFF; } }
        &.qs-right {
          &:after {
            border-left-color: #FFF; } } } }
    .qs-squares {
      .qs-square {
        &:hover {
          background: $blue !important;
          color: #FFF !important; } } } } }
