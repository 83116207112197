//===========================================
// -Buttons start
//===========================================

.btn {
  cursor: pointer;
  height: 54px;
  padding: 0 20px;
  line-height: 54px;
  display: inline-block;
  &[disabled],
  &.disabled {
    cursor: not-allowed; }
  &:disabled, &[disabled] {
    background-color: $disabled !important;
    color: #FFF !important; }
  &:focus, &:active {
    outline: none;
    box-shadow: none;
    text-decoration: none; }
  &.btn-rounded {
    background: #FFF;
    border-radius: 27px; }
  &.btn-white {
    border: solid 2px #fff;
    background: #FFF;
    color: $text-color;
    transition: all linear .15s;
    &:hover {
      background: $light-blue;
      border-color: $light-blue;
      transition: all linear .15s; }
    &:active {
      background: $main-color;
      border-color: $main-color;
      transition: all linear .15s; } }
  &.btn-red {
    background: $red;
    color: #FFF;
    font-size: rem(18);
    font-weight: 500;
    transition: all linear .15s;
    &:hover {
      background: $orange;
      transition: all linear .15s; }
    &:active {
      background: $dark-red;
      transition: all linear .15s; } } }




//===========================================
// -Buttons end
//===========================================

