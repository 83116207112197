.ui-timepicker-viewport {
  height: auto; }

.ui-timepicker-container .ui-widget.ui-widget-content {
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    background: #03536C; }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background: #03536C;
 }    //border-radius: 4px

  &::-webkit-scrollbar-thumb {
    //border-radius: 10px
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: #03536C; } }

.ui-timepicker-standard {
  padding: 0 !important;
  background: #FFF !important;
  border: solid 1px $main-color;
  .ui-timepicker-viewport {
    padding: 0 !important;
    width: 100%;
    box-sizing: border-box; }
  .ui-menu-item {
    width: 100% !important;
    margin: 5px 0 !important;
    a {
      border: none !important;
      border-bottom: none !important;
      color: #000 !important;
      cursor: pointer;
      &:hover {
        color: #FFF !important;
        border: none !important;
        background: $blue !important; }
      &.ui-state-hover {
        color: #FFF !important;
        border: none !important;
        background: $blue !important; } } }
  .ui-widget {
    width: calc(100% - 2px) !important;
    &.ui-widget-content {
      &::-webkit-scrollbar {
        background: #FFF !important; }
      &::-webkit-scrollbar-track {
        background: #FFF !important; }
      &::-webkit-scrollbar-thumb {
        background-color: #03536C !important; } } } }

