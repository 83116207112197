$calendarWidth: 293px;
$gray-darker: #515155;
$purple-light: #ffdde4;
$gray-middle: #7F7F81;
$gray-placeholder: #C4C4C4;
$brown-rgba: rgba(168, 19, 26, 0.25);
$black-blue: #232832;
$purple: #ffb3d1;

@mixin pseudo ($width, $height) {
  content: '';
  display: block;
  width: $width;
  height: $height; }

.remodal {
  width: 360px !important;

  .remodal-close:before {
    color: #D9030E !important; } }

.steps-modal {
  width: auto;
  max-width: 95%;
  padding: 45px 25px;
  background: #FFFFFF;
  box-shadow: 0 25px 50px rgba(130, 128, 141, 0.25);
  border-radius: 7px;
  overflow: hidden;

  .step-one {
    width: 532px;
    max-width: 100%; }

  .step-header {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #191919;
    display: flex;
    align-items: center;
    margin-bottom: 26px;

    .step-number {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      //font-weight: bold
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      min-width: 35px;
      height: 35px;
      min-height: 35px;
      border-radius: 50%;
      margin-right: 12px;
      background: linear-gradient(100.45deg, #FF545D -5.27%, #BF0912 107.93%);
      color: white; } }


  .locations {
    margin-bottom: 33px;

    .titles {
      padding-bottom: 15px;
      border-bottom: 1.2px solid rgba(25, 25, 25, 0.05);
      margin-bottom: 20px;

      .title {
        padding: 11px 52px 11px 48px;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
        color: #191919;
        border-radius: 30px;
        transition: all .1s ease-out;
        margin-right: 12px;
        cursor: pointer;

        &.current {
          background: rgba(234, 59, 68, 0.12); }

        &:hover {
          background: rgba(234, 59, 68, 0.12);
          opacity: .7; } } }


    .location {
      width: 100%;
      text-align: left;

      &:not(.current) {
        display: none; }

      .description {
        padding-top: 20px;
        padding-left: 47px;
        margin-bottom: 4px;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        //font-weight: normal
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: #191919; }

      .address {
        padding-left: 47px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 135%;
        color: #7F7F81;
        margin-bottom: 17px; }

      .map {
        height: 137px;
        overflow: hidden; } } }

  .next-step {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 17px 61px 15px 60px;
    background: linear-gradient(100.45deg, #FF545D -5.27%, #BF0912 107.93%);
    border-radius: 7px;
    transition: all .1s ease-out;
    position: relative;

    &.has-next:after {
      content: '';
      position: absolute;
      right: 17px;
      top: 20px;
      display: inline-block;
      width: 14px;
      height: 13px;
      background: url('../img/next-button.svg');
      background-size: 100% 100%; }

    &:hover {
      opacity: .7; }

    &:disabled {
      background: #ababab;
      color: #191919; } } }


.flatpickr-wrapper {
  height: 530px;
  overflow-y: scroll;
  //width: calc(100% + 20px)
  width: 100%;
  padding-right: 4px;

  .flatpickr-rContainer {
    width: 100%; } }


.flatpickr-calendar.inline {
    width: 100% !important;
    box-shadow: none !important;

    .flatpickr-days {
      width: 100% !important;
      flex-direction: column;

      .month-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        color: $gray-darker;
        height: 41px;
        display: block;
        width: 100%;
        text-align: center;

        // мегакостыль часть 2
        & + .dayContainer {
          padding-top: 0; } }

      .dayContainer {
        overflow: hidden;
        margin: 0 auto 33px;
        box-shadow: none !important;

        // мегакостыль часть 1
        padding-top: 41px;

        &:last-child {
          margin-bottom: 0; } } }

    .numInputWrapper {
      &:hover {
        background: transparent; }

      .arrowUp,
      .arrowDown {
        display: none; } }

    .flatpickr-months {
      display: none;
      user-select: none;

      .flatpickr-prev-month,
      .flatpickr-next-month {
        top: 4px;

        svg {
          fill: $red; } }

      .flatpickr-month {
        width: auto;
        display: inline-block;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        color: $black;
        margin-bottom: 10px;
        flex-basis: 0;

        .flatpickr-current-month {
          width: auto;
          position: static;
          font-size: 100%;
          padding: 0;
          display: inline-flex;
          align-items: center;

          span.cur-month {
            font-size: 16px;
            font-weight: 600; }

          .flatpickr-monthDropdown-months,
          .numInputWrapper input.cur-year {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-size: 16px;
            line-height: 120%;
            color: $black;
            background: transparent !important;
            border: 3px solid $white !important;
            outline: none !important;
            font-weight: 600; }

          .numInputWrapper input.cur-year {
            width: 55px;
            padding: 0;
            font-size: 16px;
            font-weight: 600; } } }

      .flatpickr-prev-month,
      .flatpickr-next-month {
        padding: 3px 14px; }

      .flatpickr-prev-month.flatpickr-disabled,
      .flatpickr-next-month.flatpickr-disabled {
        display: block;
        opacity: .4; } }

    .flatpickr-weekdays {
      display: none; }

    .dayContainer {
      width: $calendarWidth;
      min-width: $calendarWidth;
      max-width: $calendarWidth;

      .flatpickr-day {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: normal;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none;
        height: 32px;
        width: 32px;
        min-height: 32px;
        min-width: 32px;
        max-height: 32px;
        max-width: 32px;
        line-height: 1;
        margin: 0 4px 8px;
        flex-basis: 14.2%;
        box-shadow: none !important;

        &.nextMonthDay {
          &:not(.flatpickr-disabled) {
            &:not(.inRange) {
              &:not(.selected) {
                color: $gray-darker; } } } }

        &.inRange {
          background: $purple-light;
          border: 0;
          border-radius: 0;
          position: relative;
          color: $gray-darker;
          box-shadow: none;

          &:before {
            content: '';
            z-index: -1;
            display: block;
            background: $purple-light;
            width: 93px;
            height: 32px;
            position: absolute;
            opacity: 1 !important; } }

        &.selected.startRange,
        &.selected.endRange {
          color: $white;
          background: $red;
          border-radius: 50%;

          &:before {
            display: none; } }

        //&.selected.startRange.red,
        //&.selected.endRange.red
        //  &:before
        //    left: -12px
        //    width: 32px
        //    height: 32px
        //    display: block
        //    background-color: $purple-light
        //    content: ''
        //    z-index: -1
        //    position: absolute
        //    border-radius: 5px

        //&.selected.startRange.red:before
        //  left: 12px
        //
        //&.selected.endRange.red:before
        //  left: -12px
        //
        //&.selected.startRange + .selected.endRange:before
        //  width: 24px

        &.selected,
        &.startRange,
        &.endRange,
        &.selected.inRange,
        &.startRange.inRange,
        &.endRange.inRange,
        &.selected:focus,
        &.startRange:focus,
        &.endRange:focus,
        &.selected:hover,
        &.startRange:hover,
        &.endRange:hover,
        &.selected.prevMonthDay,
        &.startRange.prevMonthDay,
        &.endRange.prevMonthDay,
        &.selected.nextMonthDay,
        &.startRange.nextMonthDay,
        &.endRange.nextMonthDay {
          background: $red;
          color: $white; }

        &.flatpickr-disabled {
          cursor: not-allowed;
          color: $gray-middle !important;
          user-select: none;

          &.red {
            color: $purple !important; } }

        &.startRange {
          position: relative; }

        &.endRange {
          position: relative; }

        &.nextMonthDay {
          &:hover,
          &.startRange,
          &.endRange {
            color: $white !important; } }

        &.selected.startRange + .selected.endRange:before {
          content: '';
          z-index: -1;
          display: block;
          background: $purple-light;
          height: 32px;
          position: absolute;
          width: 50px;
          left: -100%;
          border-radius: 5px; }

        &.red,
        &.red:hover {
          &.flatpickr-disabled {
            opacity: 1 !important; }

          &:not(.startRange) {
            &:not(.endRange) {
              color: $red; } }

          &.selected {
            color: $white; }

          &.startRange {
            cursor: not-allowed; } } } } }


.weekday-names {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 15px;
  margin: 0 auto 22px;

  &:after {
    @include pseudo(200%, 1px);
    background-color: $gray-placeholder;
    position: absolute;
    bottom: 0;
    left: -50%; }

  .week {
    width: $calendarWidth !important;
    flex-shrink: 0;
    box-sizing: border-box;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    &:last-child {
      display: none; }

    .day {
      font-weight: 600;
      font-size: 12px;
      line-height: 120%;
      text-align: center;
      color: $gray-darker;
      flex-shrink: 0;

      &:last-child {
        color: $red; } } } }


.timepickers {
  padding-top: 40px;
  position: relative;
  margin-left: -24px;

  &:before {
    @include pseudo(200%, 1px);
    background-color: $gray-placeholder;
    position: absolute;
    top: 0;
    left: -50%; }

  .w-50, .w-100 {
    box-sizing: border-box;
    width: 100%; }

  .datarange-error {
    text-align: left;
    color: $red; }

  .timepicker-outer-wrapper {
    width: $calendarWidth;
    max-width: 100%; }

  .timepicker-header {
    font-style: normal;
    line-height: 120%;
    margin: 0;
    text-align: left;
    font-weight: 600;
    font-size: 12px;
    color: $gray-darker;
    padding-left: 10px; }

  .timepicker-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 22px;
    margin-top: -12px;

    .before,
    .after {
      display: inline-block;
      flex-shrink: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 120%;
      text-align: center;
      color: $gray-middle;
      visibility: visible !important;
      background: $white;
      margin-bottom: 2px;
      width: 62px; }

    .before {
      padding-right: 15px; }

    .after {
      padding-left: 15px; } }

  .irc-wrapper {
    display: inline-block;
    width: 100%;

    .irs {
      .irs-handle {
        background: $red;
        box-shadow: 0 4px 12px $brown-rgba;
        border-radius: 50%;
        width: 22px;
        min-width: 22px;
        height: 22px;
        min-height: 22px;
        border: none;

        i {
          display: none; } }

      .irs-line,
      .irs-bar {
        height: 2px;
        background: $purple; }

      .irs-single {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
        color: $black-blue;
        top: -9px;
        background-color: $white;
        padding: 3px 7px;

        &:before {
          display: none; } }

      .irs-min,
      .irs-max {
        display: none; } } }

  .step-two {
    width: 671px; } }


.timepickers-description {
  p {
    font-size: 14px;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 15px; } } }

.daterangepicker {
  z-index: 10000 !important; }

@media screen and (max-width: 1199px) {
  .steps-modal {
    padding: 34px 15px 47px;

    .locations {
      .location {
        .description,
        .address {
          padding-left: 0; } }

      .titles {
        justify-content: center;

        .title {
          margin-right: 0;
          padding: 8px 20px; } } }

    .calendar,
    .timepickers {
      width: 100% !important;
      margin-left: 0; } } }


@media screen and (min-width: 1000px) {
  .remodal {
    width: auto !important; }

  .steps-modal {
    .step-two {
      max-width: 762px; }

    .flatpickr-wrapper {
      width: 100%;
      height: auto;
      overflow: inherit; }


    .flatpickr-calendar.inline {

      .flatpickr-months {
        display: flex;
        justify-content: space-between;

        .flatpickr-month {
          max-width: 293px !important;
          text-align: center; } }


      .flatpickr-days {
        flex-direction: row;
        justify-content: space-between;

        .dayContainer {
          display: none;
          padding-top: 0;
          margin: 0;

          &:before {
            display: none; }

          &:nth-child(1), &:nth-child(2) {
            display: flex;
            height: 240px; } } } }

    .weekday-names {
      .week {
        margin: 0;
        padding: 0;

        &:last-child {
          display: grid; } } } } }
