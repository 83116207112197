.catalog-filters {
  padding: 34px 0 14px;

  button.filter {
    margin-bottom: 20px; } }

.filter-label {
  display: inline-block;
  position: relative;
  padding-left: 40px;
  line-height: 30px;
  font-size: rem(17);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      & ~ .checkmark {
        border: solid 2px $blue;
        background: $blue url("../img/check.svg");
        background-position: center;
        background-repeat: no-repeat; } }
    &#submit-politica {
      left: 50%; } }
  &:hover {
    input {
      & ~ .checkmark {
        background-color: $blue;
        border-color: $blue; } } }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: #fff;
    border: solid 2px $main-color; } }

.filter {
  width: 180px;
  height: 30px;
  background: #FFF;
  border: 1px solid #03536C;
  border-radius: 10px;
  position: relative;
  color: $text-color;
  margin-right: 10px;
  i {
    position: absolute;
    top: 6px;
    left: 20px;
    color: $text-color; }
  &.active {
    background: #25B1CF;
    border-color: #25B1CF;
    color: #FFF;
    i {
      color: #FFF; } } }

.api-catalog-results {
  box-sizing: border-box;

  h2 {
    padding: 20px 15px 33px;
    font-weight: 600;
    font-size: 32px;
    text-align: center;
    width: 100%; }

  .image-wrapper {
    margin-top: 30px !important; } }

.catalog-item {
  margin-bottom: 20px;
  background: #FFF;
  border-radius: 10px;
  padding: 26px 32px 64px;
  transition: all linear .15s;
  position: relative;
  overflow: hidden;

  .inactive-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: white;
      opacity: .55;
      z-index: 7; }

    span {
      padding: 14px 38px;
      font-size: 16px;
      color: #2f2e2e;
      background: white;
      z-index: 8;
      opacity: .8; } }

  &:hover {
    box-shadow: 0 20px 50px 0 rgba(0,0,0,.1);
    transition: all linear .15s; }
  h4 {
    font-size: rem(32);
    font-weight: 600;
    margin-bottom: 11px; }

  .guaranteed, .similar {
    color: #D9030E;
    font-size: rem(17);
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    position: relative;
    padding-bottom: 7px;
    &:before {
      width: 56px;
      height: 1px;
      background: #D9030E;
      display: block;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0; } }

  .similar {
    color: #5D5D60;
    &:before {
      background: #5D5D60; } }

  .image-wrapper {
    text-align: center;
    position: relative;
    max-width: 100%;
    width: 500px;
    margin: auto auto 0;
    img {
      margin: 0 auto; } }
  .year {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 10px;
    background: #25B1CF;
    padding: 3px 20px;
    color: #FFF;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;

    .gracias {
      font-size: rem(24); } }
  .type {
    font-size: rem(17);
    margin-bottom: 40px;
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 40px;
      height: 1px;
      background: $red;
      position: absolute;
      left: 0;
      bottom: -15px; } }
  .car-params {
    padding: 40px 36px 25px;
    margin-bottom: 48px;
    color: #191919;
    font-size: rem(17);
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 10px;
    background: #F0FCFF;
    .item {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 0;
      span {
        width: 60%;
        display: block;
        font-weight: 500; } }
    .block-title {
      margin-top: 17px;
      padding-top: 17px;
      position: relative;
      color: #5D5D60;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      &:before {
        width: 36px;
        height: 1px;
        background: #D9030E;
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0; } } }

  .day-price, .total-price {
    padding-left: 36px;
    text-align: left; }
  .day-price {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    color: #D9030E;
    margin-bottom: 7px; }

  .total-price {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 130%;
    color: #000000; }

  table {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
    tr {
      border-bottom: solid 2px #fff;
      td {
        width: 50%;
        background: #F6F6F6;
        padding: 7px 20px; } } }
  .btn-order {
    background: transparent;
    //padding: 16px 40px
    border-radius: 27px;
    border: solid 2px $red;
    transition: all linear .15s;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 3px 14px;
    font-size: 15px;
    &:hover {
      background: $red;
      color: #FFF;
      transition: all linear .15s; } } }

.more-cars {
  padding: 60px 0;
  text-align: center;
  a {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: rem(18);
    color: $main-color;
    transition: all linear .15s;
    .fa {
      margin-right: 10px; }
    &:hover {
      color: $blue;
      transition: all linear .15s; } } }

.outer-filter {
  user-select: none;
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  background: #FFFFFF;
  border-radius: 6px;
  margin: 0;
  padding: 14px 33px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #5D5D60;
  display: block;

  &:hover {
    color: #5D5D60; }

  &:not(.catalog-filter) {
    //margin-top: 21px
    margin-top: 0;
    padding-top: 0;


    .location {
      margin-bottom: 21px !important; } }

  &.catalog-filter {
    width: 100%;
    max-width: 100%;
    .location, .dates {
      display: flex;
      flex-wrap: nowrap !important;
      &:after {
        content: '';
        display: block;
        width: 16px;
        height: 17px;
        margin-left: 25px;
        background: url('/assets/img/edit.svg');
        background-size: 100% 100%;
        flex-shrink: 0; } }
    .location {
      margin-bottom: 20px;
      &:after {
        margin-left: auto; } } }

  .location, .dates {
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 2;
    &:before {
      content: '';
      display: block;
      width: 16px;
      height: 24px;
      margin-right: 16px;
      background: url('/assets/img/location.svg');
      background-size: 100% 100%;
      flex-shrink: 0; }
    b {
      font-weight: 500;
      margin-bottom: 3px;
      line-height: normal;
      color: #191919; }
    p.small {
      margin-bottom: 0;
      line-height: normal;
      font-size: 14px;
      font-style: normal;
      font-weight: 400; } }

  .dates {
    flex-wrap: wrap;
    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 24px;
      margin-right: 12px;
      background: url('/assets/img/timer.svg');
      background-size: 100% 100%;
      flex-shrink: 0; }
    .date-column {
      max-width: 45%;
      box-sizing: border-box;
      &:last-child {
        p.small {
          min-width: 150px; } } }
    .delimiter {
      width: 10%;
      box-sizing: border-box;
      min-width: 30px;
      b {
        text-align: center;
        display: block; } }
    .wrapper {
      max-width: calc(100% - 32px - 16px);
      width: 100%; }
    span {
      flex-shrink: 0; } } }


ul.included-options {
  padding: 35px 33px;
  margin-bottom: 0;
  border-radius: 10px;
  background: #F0FCFF;
  li {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: #191919;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &:before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background-image: url('/assets/images/tick.svg');
      background-position: center center;
      background-repeat: no-repeat;
      margin-right: 16px;
      flex-shrink: 0; }
    &:last-child {
      margin-bottom: 0; } } }

.order-summary {
  border-radius: 10px;
  background: #F0FCFF;
  padding: 41px 36px !important;

  p {
    color: #191919;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 0;
    b {
      font-weight: 500;
      display: inline-block;
      width: 50%; }
    span.red-text {
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; }

    &.gracias {
      color: var(--black, #191919);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      margin-top: 36px; } } }

.page-title .outer-filter {
  padding: 27px 23px 32px;
  margin: 0 0 14px;
  max-width: 100%;
  width: 100%; }


@media screen and (max-width: 1200px) {
  .page-title .outer-filter {
    .location, .dates {
      width: 100%;
      font-size: 14px;
      text-align: left;
      &:after {
        margin-left: auto; } }

    .location {
      margin-right: 0 !important; } } }

@media screen and (max-width: 991px) {
  .catalog-item {
    .row {
      & > div:first-child {
        flex-direction: column !important;
        justify-content: space-between;
        display: flex; } }
    .car-params {
      font-size: rem(14);
      span {
        font-size: rem(16); } } }
  .outer-filter {
    padding: 27px 30px 32px;
    margin: 14px auto;

    .location, .dates {
      width: 100%;
      font-size: 14px;
      text-align: left;
      &:after {
        margin-left: auto; } }

    .location {
      margin: 0; } }

  .outer-container {
    padding-left: 15px;
    padding-right: 15px; } }

@media screen and (max-width: 767px) {
  .col-lg-6-5 {
    width: 300px; }
  .catalog-item {
    .car-params {
      margin: 20px 0;
      padding: 20px 25px;
      .item {
        span {
          width: 50%;
          flex-shrink: 0; } } }
    .car-title {
      .type {
        &:after {
          display: none; } } }
    table {
      margin-top: 20px;
      text-align: left; }
    .day-price, .total-price {
      padding-left: 0;
      text-align: center; }
    .day-price {
      font-size: 21px; } }
  .catalog-filters {
    padding: 34px 0 14px;
    .filter {
      margin-bottom: 20px; } }
  .api-catalog-results {
    h2 {
      font-size: 24px; } }
  .outer-filter {
    padding: 27px 23px 32px;
    margin: 0 0 14px;
    max-width: 100%;
    width: 100%; }
  .order-summary {
    padding: 20px 25px !important;

    p {
      font-size: 15px;
      &.gracias {
        font-size: 20px; } } } }

@media screen and (max-width: 430px) {
  .catalog {
    .container {
      padding: 0; } }

  .catalog-filters {
    padding: 0 0 20px; }

  .catalog-item {
    padding: 20px;
    .car-title {
      h4 {
        font-size: rem(24); }
      .type {
        font-size: rem(13); } }
    .car-params {
      .col-3 {
        font-size: rem(13);
        padding-left: 5px;
        padding-right: 5px;
        span {
          font-size: rem(15); } } }
    table {
      td {
        font-size: rem(15); } }
    .btn-order {
      width: 100%; }
    .image-wrapper {
      margin-top: 20px; }
    .year {
      font-size: 1rem;
      padding: 3px 14px; } }
  .catalog-filters {
    .filter {
      width: 100%; } }
  .api-catalog-results {
    margin: 0 auto;
    max-width: 100%;
    box-sizing: border-box; }
  .outer-filter {
    &:not(.catalog-filter) {
      padding: 0; }
    &.catalog-filter {
      .location {
        //&:after
 }        //  margin-left: 20px
      .dates {
        .date-column {
          width: 100% !important;
          max-width: 100% !important;
          &:first-child {
            margin-bottom: 12px; } }

        .delimiter {
          display: none; } } }
    .dates, .location {
      p.small {
        font-size: 12px; } } } }

@media (min-width: 992px) {
  .outer-filter {
    &.catalog-filter {
      width: 100%;
      max-width: 100%;
      .location, .dates {
        flex-wrap: nowrap !important;
        margin-bottom: 0 !important; }
      .location {
        width: 50%;
        &:after {
          margin-left: 20px; } }
      .dates {
        width: 50%;
        padding-left: 40px; } } }
  .catalog-item .btn-order {
    font-size: 18px;
    padding: 4px 25px; }
  .col-lg-6-5 {
    width: calc(100% - 500px);
    max-width: calc(100% - 500px);
    box-sizing: border-box; }
  .col-lg-5-5 {
    width: 500px;
    max-width: 500px;
    box-sizing: border-box; } }
