//===========================================
// -Forms Start
//===========================================

.inset-input {
  position: relative;
  .form-control {
    height: 70px;
    border: none;
    box-shadow: inset 2px 5px 10px rgba(24, 35, 5, 0.05);
    background: #FAFAFA;
    border-radius: 5px;
    padding: 0 30px;
    font-size: rem(18); }
  button {
    border: none;
    background: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 70px;
    text-align: center;
    @include animation(all, .3s, linear);
    img {
      margin: 0 auto; }
    &:hover {
      opacity: .5;
      @include animation(all, .3s, linear); } } }

//===========================================
// -Forms end
//===========================================
