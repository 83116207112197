.promo-block {
  display: flex;
  align-items: stretch;
  height: 490px;
  width: 100%;
  margin: 60px 0;
  .promo-photo {
    width: 50%;
    display: block;
    background: url("../img/promo-img.jpeg");
    background-size: cover; }
  .promo-content {
    width: 50%;
    background: $main-color;
    position: relative;
    padding: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    h4 {
      color: #FFF;
      font-size: rem(48); }
    p {
      color: #FFF;
      font-size: rem(24); }
    .btn {
      width: 200px; }
    &:after {
      content: '';
      position: absolute;
      width: 73px;
      height: 66px;
      background: url("../img/vector.svg");
      background-size: contain;
      top: -15px;
      left: 75px; } } }

@media screen and (max-width: 1199px) {
  .promo-block {
    height: 400px;
    .promo-content {
      padding: 80px 30px 40px;
      flex-direction: column;
      justify-content: space-between;
      h4 {
        font-size: rem(42); } } } }

@media screen and (max-width: 991px) {
  .promo-block {
    height: 350px;
    .promo-content {
      padding: 80px 30px 40px;
      flex-direction: column;
      justify-content: space-between;
      h4 {
        font-size: rem(28); }
      p {
        font-size: rem(20); } } } }

@media screen and (max-width: 767px) {
  .promo-block {
    display: block;
    height: auto;
    .promo-photo {
      width: 100%;
      height: 200px; }
    .promo-content {
      width: 100%;
      padding: 30px;
      display: block;
      &:after {
        top: -230px;
        left: 70%; }
      h4 {
        font-size: rem(28);
        margin-bottom: 20px; }
      p {
        font-size: rem(20);
        margin-bottom: 20px; } } } }

@media screen and (max-width: 575px) {
  .promo-block {
    .promo-content {
      text-align: center; } } }
