//===========================================
// -Variables start
//===========================================

// Colors start

// define your vars or override bootstrap vars
$black: #000;
$disabled: #CCCCCC;
$red: #D9030E;
$dark-red: #750006;
$gray: #B7B7B7;
$blue: #25B1CF;
$dark-blue: #03536C;
$orange: #F16500;
$light-blue: #A7EDFF;
$text-color: #191919;
$main-color: #086B88;

// Typography Family
$font-default: "Roboto", sans-serif;
$font-primary: "Montserrat", sans-serif;

$font-path: "../fonts";

// define your Break Points or override bootstrap Break Points
//$grid-breakpoints: (
//  xs: 0,
//  sm: 576px,
//  md: 768px,
//  lg: 992px,
//  xl: 1200px
//)
// ===========================================
// -Variables end
// ===========================================
