.text-block {
  padding-top: 25px;
  padding-bottom: 85px;
  background: white;

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 130%;
    color: #000000;
    padding: 0;
    margin-bottom: 56px; }

  .image {
    padding-top: 17px;
    margin-bottom: 64px;

    img {
      max-width: 100%;
      margin: 0 auto; } }

  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    .button {
      display: inline-block;
      padding: 16px;
      min-width: 200px;
      height: 55px;
      right: 1250px;
      top: 2956px;
      background: #D9030E;
      border-radius: 50px;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: #FFFFFF;
      border: none;

      &:hover {
        background: #F16500;
        transition: all linear .15s; } } }

  .text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    text-align: justify;
    color: #000000;

    p {
      margin-bottom: 25px; }

    b, strong {
      font-weight: bold; }

    a {
      color: #D9030E;

      &:hover {
        text-decoration: underline; } } } }

