.home-banner {
  width: 100%;
  height: 850px;
  background: #FFF url('../img/home-banner.jpeg') no-repeat;
  background-size: cover;
  background-position: bottom;
  .container {
    .row {
      .banner-content {
        height: 850px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1 {
          color: #000;
          font-size: rem(58);
          font-weight: 500;
          margin-bottom: 20px;
          position: relative;
          &:after {
            content: '';
            display: block;
            width: 73px;
            height: 66px;
            position: absolute;
            background: url('../img/red-vector.svg') no-repeat;
            background-size: cover;
            top: 0;
            left: -70px; } }
        h2 {
          font-size: rem(26);
          font-weight: 400;
          //margin-bottom: 40px
          margin-bottom: 70px; }

        .contacts {
          width: 340px;
          background: $blue;
          padding: 25px 30px;
          color: #FFF;
          p {
            color: #FFF;
            font-size: rem(20);
            margin-bottom: 10px;
            font-weight: 500; }
          a {
            color: #FFF;
            font-weight: 600;
            font-size: rem(24); }
          .banner-icons {
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            width: 100%;
            a {
              display: flex;
              width: 60px;
              height: 60px;
              background: #FFF;
              border-radius: 30px;
              border: solid 1px #FFF;
              margin-right: 15px;
              align-items: center;
              justify-content: center;
              transition: all linear .15s;
              &:hover {
                background: $light-blue;
                border-color: $light-blue;
                transition: all linear .15s; }
              &:active {
                background: $main-color;
                border-color: $main-color;
                transition: all linear .15s; } } } }
        .button-to-time-overlay {
          display: inline-block;
          padding: 43px 38px;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          color: #FFFFFF;
          background: #D9030E;
          box-shadow: 0 38px 50px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          border: none;
          transition: all .3s ease-out;

          &:hover {
            opacity: .7; } } } } } }
.open-time-overlay-wrapper {
  position: relative;
  z-index: 9; }


@media screen and (max-width: 1199px) {
  .home-banner {
    height: 650px;
    .container {
      .row {
        .banner-content {
          height: 650px;
          h1 {
            margin-top: 40px;
            &:after {
              display: none; } } } } } } }

@media screen and (max-width: 991px) {
  .home-banner {
    height: 500px;
    .container {
      .row {
        .banner-content {
          height: 500px;
          h1 {
            font-size: rem(42);
            margin-top: 80px;
            margin-bottom: 15px;
            line-height: 1; }
          h2 {
            font-size: rem(32);
            margin-bottom: 15px;
            line-height: 1; }
          .contacts {
            padding: 20px 25px; }
          .button-to-time-overlay {
            padding: 28px 24px;
            font-size: 19px; } } } } } }


@media screen and (max-width: 575px) {
  .home-banner {
    height: 400px;
    overflow: visible;
    margin-bottom: 350px;
    .container {
      padding-left: 0;
      padding-right: 0;
      .row {
        margin: 0;
        .banner-content {
          margin-top: 400px;
          background: #FFF;
          height: auto;
          text-align: center;
          padding-bottom: 30px;
          h1 {
            margin-top: 20px;
            font-size: rem(32); }
          h2 {
            font-size: rem(24);
            margin-bottom: 20px;
            br {
              display: none; } }
          .contacts {
            width: 100%;
            .banner-icons {
              display: flex;
              justify-content: center; } }
          .button-to-time-overlay {
            padding: 18px 14px;
            font-size: 16px; } } } } } }


@media screen and (max-width: 480px) {
  .home-banner {
    height: 400px;
    background-position: 100%; } }

@media screen and (max-width: 430px) {
  .home-banner {
    height: 300px;
    background-position: 80%;
    margin-bottom: 280px;
    .container {
      .row {
        .banner-content {
          margin-top: 300px;
          h1 {
            font-size: rem(28); }
          h2 {
            font-size: rem(20); }
          .contacts {
            a {
              font-size: 20px; } } } } } } }
