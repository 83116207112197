.section-title {
  font-size: rem(48);
  text-align: center; }

.page-title {
  padding: 60px 0 0;
  h1 {
    color: $text-color;
    font-size: rem(58);
    position: relative;
    margin-bottom: 33px;

    &:after {
      content: '';
      display: block;
      width: 73px;
      height: 66px;
      position: absolute;
      background: url('../img/red-vector.svg') no-repeat;
      background-size: cover;
      top: 0;
      left: -70px; }
    .red-payed {
      color: #D9030E; } }
  .red-button {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 16px 40px;
    border-radius: 50px;
    background: #D9030E;
    display: inline-block;
    margin-bottom: 50px;
    transition: all .2s ease-in-out;

    &:hover {
      opacity: .85; }

    p {
      color: $text-color;
      font-size: rem(36);
      margin-bottom: 0; }

    &.small {
      font-size: rem(24);
      a {
        color: #03536C;
        transition: .15s all linear;
        &:hover {
          color: $red;
          transition: .15s all linear; } } } } }

@media screen and (max-width: 1199px) {
  .page-title {
    h1 {
      &:after {
        display: none; } } } }

@media screen and (max-width: 991px) {
  .page-title {
    padding: 40px 0 0;
    h1 {
      font-size: rem(42); }
    p {
      font-size: rem(28); } } }

@media screen and (max-width: 575px) {
  .page-title {
    text-align: center;
    padding: 30px 0 0;
    h1 {
      font-size: rem(32); }
    p {
      font-size: rem(20); } } }

@media screen and (max-width: 480px) {
  .page-title {
    padding: 30px 0 20;
    text-align: center;

    p {
      margin-bottom: 1rem; }

    .container {
      padding-left: 15px;
      padding-right: 15px;
      h1 {
        font-size: rem(28); }
      p {
        font-size: rem(15); } } } }
