.car-images-slider.slider {
  margin-bottom: 16px;

  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      transform: translate(0px, 0);

      &:before {
        background-color: white; } } }

  .swiper-wrapper {
    .item {
      width: 100%;

      img {
        max-width: 100%;
        width: 100%;
        border-radius: 12px;
        display: block;
        margin: 0 auto; }

      .images-counter {
        position: absolute;
        bottom: 13px;
        right: 13px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 110%;
        text-align: center;
        color: #7F7F81;
        display: inline-flex;

        &:before {
          content: '';
          width: 18px;
          height: 14px;
          display: block;
          margin-right: 6px;
          background: url('/assets/img/before-images-counter.svg');
          background-size: 100% 100%;
          background-position: center center;
          background-repeat: no-repeat; } } } }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    transition: all .2s ease-out;

    &:before {
      position: absolute;
      display: block;
      content: "";
      width: 55px;
      height: 55px;
      line-height: 55px;
      border-radius: 50%;
      z-index: 0;
      transition: all 0.2s ease;
      background: transparent; }

    &:after {
      content: '';
      display: block;
      width: 16px;
      height: 10px;
      background: url('/assets/img/location-after.svg');
      background-repeat: no-repeat;
      z-index: 1;
      position: relative;
      transform: rotate(90deg);
      left: -2px;
      top: 1px; }

    &:hover:before {
      background: #ffe3e3; } }

  .swiper-button-prev {
    left: 30px;
    transform: translate(-80px, 0); }

  .swiper-button-next {
    right: 30px;
    transform: translate(80px, 0);

    &:after {
      transform: rotate(270deg);
      left: 1px;
      top: -2px; } } }

@media (min-width: 992px) {
  .car-images-slider.slider {
    margin-bottom: 0;
    margin-top: auto;
    //margin-top: -56px

    .swiper-wrapper {
      height: auto !important;

      .item {
        border-radius: 6px;

        .images-counter {
          bottom: 31px;
          right: 30px; } } }

    .swiper-button-prev,
    .swiper-button-next {
      display: inline-flex; } } }
