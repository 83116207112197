.advantages {
  padding: 60px 0;
  background: #FFF;
  .advantages-container {
    display: flex;
    justify-content: space-between;
    .advantage {
      width: 15%;
      h4 {
        font-size: rem(24);
        font-weight: 500;
        display: block;
        position: relative;
        margin-bottom: 25px;
        padding-bottom: 25px;
        &:after {
          content: '';
          display: block;
          position: absolute;
          background: $red;
          width: 40px;
          height: 1px;
          bottom: 0; } }
      p {
        font-size: rem(17);
        color: $text-color; } } } }

@media screen and (max-width: 991px) {
  .advantages {
    padding: 60px 0 20px;
    .container {
      max-width: none;
      .advantages-container {
        display: block;
        .advantage {
          vertical-align: top;
          display: inline-block;
          padding: 0 10px;
          width: 32%;
          margin-bottom: 40px;
          h4 {
            min-height: 75px;
            padding-bottom: 15px;
            margin-bottom: 15px;
            br {
              display: none; } } } } } } }

@media screen and (max-width: 575px) {
  .advantages {
    padding: 60px 0 20px;
    .container {
      max-width: none;
      .advantages-container {
        display: block;
        .advantage {
          vertical-align: top;
          display: inline-block;
          padding: 0 10px;
          width: 49%;
          margin-bottom: 30px;
          h4 {
            min-height: 75px;
            padding-bottom: 15px;
            margin-bottom: 15px;
            font-size: rem(20);
            br {
              display: none; } }
          p {
            font-size: rem(17); } } } } } }

@media screen and (max-width: 480px) {
  .advantages {
    padding: 60px 0 20px;
    .container {
      max-width: none;
      .advantages-container {
        display: block;
        .advantage {
          vertical-align: top;
          display: inline-block;
          padding: 0 20px;
          width: 100%;
          h4 {
            min-height: auto;
            padding-bottom: 15px;
            margin-bottom: 15px;
            br {
              display: none; } } } } } } }

@media screen and (max-width: 430px) {
  .advantages {
    .container {
      padding: 0;
      .row {
        margin: 0;
        .advantages-container {
          .advantage {
            padding-left: 5px;
            padding-right: 5px; } } } } } }
