.empty-results {
  padding: 30px 10px;
  margin-bottom: 20px;
  border-radius: 6px; }

.page-404,
.empty-results {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 90px;
  background: #fff;

  .btn {
    height: auto !important;
    min-height: 54px; }

  .container-404 {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../img/404-bg.svg') no-repeat;
    background-size: contain;
    background-position: center;
    &>.row {
      justify-content: center; }
    .content-404 {
      width: 100%;
      max-width: 800px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1 {
        font-size: rem(42);
        font-weight: 500; }
      .btn {
        padding: 0 15px;
        color: $text-color;
        font-weight: 500;
        background: #A7EDFF;

        &:hover {
          border-color: #A7EDFF; } }
      .logo-404 {
        width: 140px;
        margin-bottom: 60px; }
      .img-404 {
        width: 120%; } }
    .copy-404 {
      margin-top: 20px;
      width: 100%;
      color: #B7B7B7;
      font-size: rem(15);
      text-align: center; } } }


.empty-results {
  position: static; }

@media screen and (max-width: 1199px) {
  .page-404 {
    padding: 40px;
    .container-404 {
      .header-404 {
        .logo-404 {
          margin-bottom: 20px; } }
      .content-404 {
        .img-404 {
          max-width: 540px;
          margin: 0 auto; } } } } }

@media screen and (max-width: 575px) {
  .page-404 {
    padding: 30px 0;
    .container-404 {
      background: none;
      .header-404 {
        text-align: center;
        .logo-404 {
          margin: 0 auto 20px; } }
      .content-404 {
        background: url('../img/404-bg.svg') no-repeat;
        background-size: contain;
        background-position: center;
        background-position-y: 110%;
        .img-404 {
          max-width: 100%; }
        .body-404 {
          text-align: center;
          h1 {
            font-size: rem(32); }
          .btn-white {
            margin-top: 10px;
            color: #FFF;
            background: $red;
            border: solid 1px $red;
            transition: all linear .15s;
            margin-bottom: 50px;
            &:hover {
              transition: all linear .15s;
              background: $orange;
              border-color: $orange; }
            &:active {
              transition: all linear .15s;
              background: $dark-red;
              border-color: $dark-red; } } } } } }

  .page-404, .empty-results {
      padding: 15px 20px;
      box-sizing: border-box; } }

@media screen and (max-width: 575px) {
  .page-404 {
    .container-404 {
      .content-404 {
        .body-404 {
          text-align: center;
          h1 {
            font-size: rem(24); } } } } } }
