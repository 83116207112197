.navbar {
  padding: 15px 0;
  background: #fff;
  width: 100%;
  &.bg-transparent {
    position: absolute;
    z-index: 1;
    background: transparent; }
  & > .container {
    display: flex;
    justify-content: space-between;
    .collapse {
      justify-content: center;
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: $text-color;
            transition: all linear .15s;
            margin: 0 25px;
            font-size: rem(20); }
          &.active, &:hover {
            .nav-link {
              color: $red;
              transition: all linear .15s; } } } } } }
  .btn {
    border: solid 2px $red;
    color: $text-color;
    font-weight: 500;
    font-size: rem(18);
    padding: 0 40px;
    transition: all linear .15s;
    background: transparent;
    &:hover {
      background: $orange;
      border-color: $orange;
      color: #fff;
      transition: all linear .15s; }
    &:active {
      background: $dark-red;
      border-color: $dark-red; } } }
.mobile-menu {
  display: none; }

@media screen and (max-width: 991px) {
  .navbar {
    padding: 15px 30px;
    .btn-rounded {
 }      //margin-left: calc(100% - 450px)
    .navbar-toggler {
      border: none;
      display: flex;
      width: 24px;
      height: 16px;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
      padding: 0;
      span {
        display: block;
        width: 24px;
        height: 2px;
        border-radius: 1px;
        background: $red; } } }
  .mobile-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba($dark-blue, .9);
    z-index: 10;
    align-items: center;
    justify-content: center;
    .mobile-container {
      margin-bottom: 20%;
      position: relative;
      width: 100%;
      max-width: 440px;
      padding: 100px 20px;
      ul {
        padding: 0;
        li {
          list-style: none;
          text-align: center;
          margin-bottom: 30px;
          a {
            color: #FFF;
            text-transform: uppercase;
            font-size: rem(24);
            &:hover {
              color: $blue; } } } }
      .mobile-close {
        font-weight: 400;
        right: 20px;
        top: 20px;
        left: auto;
        width: 40px;
        height: 40px;
        border: none;
        background: transparent;
        color: #25B1CF;
        font-size: 40px;
        position: absolute; } } } }


@media screen and (max-width: 575px) {
  .navbar {
    .btn-rounded {
      font-size: rem(13);
      height: 52px;
      border-radius: 26px;
      line-height: 52px;
      //margin-left: calc(100% - 400px)
      padding: 0 40px; }
    .navbar-brand {
      img {
        height: 52px; } } } }

@media screen and (max-width: 430px) {
  .navbar {
    padding: 15px 20px;
    .btn-rounded {
      font-size: rem(13);
      height: 40px;
      border-radius: 20px;
      line-height: 37px;
      //margin-left: calc(100% - 300px)
      padding: 0 20px; }
    .navbar-brand {
      margin-top: 5px;
      img {
        height: 30px; } } } }

