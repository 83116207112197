//===========================================
// -Helper Classes start
//===========================================

.center {
  margin: auto; }

.main {
  padding: 120px; }

.padding-section {
  padding: 120px; }

.mobile-block {
  display: none; }

@media (max-width: 500px) {
  .padding-section {
    width: 100%;
    padding: 20px;
    overflow: hidden; }

  .desktop-block {
    display: none; }

  .mobile-block {
    display: block; } }

//===========================================
// -Helper Classes end
//===========================================
